import React from "react";
import classnames from 'classnames';

interface CoreValueProp {
  className?: string,
  title: string,
  icon: string,
  message: string,
}

export default class CoreValue extends React.Component<CoreValueProp> {

  render () {
    const { className = '', title, icon, message } = this.props;
    return (
      <div className={classnames(className)}>
        <style jsx>{`
          .core-values-icons {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        `}</style>
        <div className="h-7 p-relative d-b">
          <div className="w-7 h-7 br-full m-auto bgc-navy-50" />
          <img
            src={icon}
            className="d-b p-absolute pin-t core-values-icons"
            style={{ height: "150px", width: "150px" }}
          />
        </div>
        <h4 className="pt-1 lg:pt-1p5 fs-xl color-primary">{title}</h4>
        <p className="px-3 lg:px-p5 pt-p5 fs-lg" style={{ fontWeight: 500 }}>{message}</p>
      </div>
    )
  }

}