import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import CoreValue from './CoreValue';
import Image from 'inkp-components/dist/Components/Image';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

const IMAGES = {
  hero_background: 'https://inkp-production.32pt.com/public/assets/Hero_Background.jpg',
  core_values_1: 'https://inkp-production.32pt.com/public/assets/Core+Value+Icons-01.svg',
  core_values_2: 'https://inkp-production.32pt.com/public/assets/Core+Value+Icons-02.svg',
  core_values_3: 'https://inkp-production.32pt.com/public/assets/Core+Value+Icons-03.svg',
  support_mockup: 'https://inkp-production.32pt.com/public/assets/Support+Mockup.jpg',
  our_product_asset: 'https://inkp-production.32pt.com/public/assets/Our+Product+Asset.png',
  our_products_asset: 'https://inkp-production.32pt.com/public/assets/Our+Products+Asset.png',
};

const CORE_VALUES = [
  { title: 'Simple Pricing', icon: IMAGES.core_values_1, message: 'Up-front, per-unit pricing & FREE shipping' },
  { title: 'Fast Shipping', icon: IMAGES.core_values_2, message: 'Get your order in as little as 3 days' },
  {
    title: 'Great Selection',
    icon: IMAGES.core_values_3,
    message: 'Whatever your group needs to look their best, we have it',
  },
];

export default class About extends React.Component<RouteComponentProps> {
  renderOurMission() {
    return (
      <div className="py-2 lg:py-3p5 d-b m-auto lg:w-container">
        <h3 className="ta-center fs-2xl">Our Mission</h3>
        <div className="flex justify-center pt-1 lg:pt-1p5">
          <p className="ta-center fs-xl w-full lg:w-1/2 px-1" style={{ fontWeight: 500 }}>
            Ordering customized products should be fun and easy!
          </p>
        </div>
        <div className="flex flex-wrap ta-center pt-p5 lg:-mx-2">
          {CORE_VALUES.map((coreValue, coreValueIndex) => (
            <CoreValue
              key={`core-value-${coreValueIndex}`}
              className="w-full pt-1p5 lg:pt-2p5 lg:w-1/3 lg:px-2"
              title={coreValue.title}
              icon={coreValue.icon}
              message={coreValue.message}
            />
          ))}
        </div>
      </div>
    );
  }

  renderOurStory() {
    return (
      <div className="py-2 lg:py-3p5 d-b m-auto lg:w-container">
        <style jsx>{`
          .our-story-text {
            width: 100%;
          }

          @media only screen and (min-width: 1024px) {
            .our-story-text {
              width: 691px;
              margin: auto;
            }
          }
        `}</style>
        <div className="mx-1 lg:mx-0">
          <Image src={IMAGES.support_mockup} className="d-b br" styles={{ objectFit: 'cover' }} />
          <div className="our-story-text">
            <h3 className="pt-2 lg:pt-2p5 fs-2xl fw-extra-bold">Our Story</h3>
            <p className="pt-1 lg:pt-1p5 lg:fs-xl">
              We understand the pride and passion you've got for your organization. At inkpop, we feel the same way
              about making custom gear. We'll stop at nothing in order to provide you with the highest quality,
              delivered on time, and within your budget. Your group will be as proud wearing it as we are making it.
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderOurProducts() {
    return (
      <div className="py-2 lg:py-3p5 d-b m-auto lg:w-container">
        <Image
          src={IMAGES.our_product_asset}
          className="lg:d-n m-auto our-products-image"
          styles={{ height: '260px', objectFit: 'contain' }}
        />
        <Image
          src={IMAGES.our_products_asset}
          className="d-n lg:d-b m-auto our-products-image"
          styles={{ height: '360px', objectFit: 'contain' }}
        />
        <h3 className="pt-2 lg:pt-2p5 fs-2xl fw-extra-bold ta-center">Our Products</h3>
        <div className="flex justify-center">
          <p className="pt-1 lg:pt-1p5 lg:w-1/2 lg:fs-xl mx-1 ta-center" style={{ fontWeight: 500 }}>
            Better goods, better for the environment. Recycling systems and water-based inks are just the beginning.
          </p>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    return (
      <React.Fragment>
        <style jsx>{`
          .hero-background-container {
            height: 691px;
          }

          @media only screen and (min-width: 1024px) {
            .hero-background-container {
              height: 484px;
            }
          }
        `}</style>
        <Helmet>
          <title>About</title>
        </Helmet>
        <div className="d-b m-auto lg:w-container">
          <div className="p-relative flex items-center justify-center hero-background-container">
            <Image
              src={IMAGES.hero_background}
              className="lg:brb p-absolute pin-t pin-l m-auto h-full"
              styles={{ objectFit: 'cover' }}
            />
            <p
              className="ta-center color-white fw-extra-bold fs-3xl p-absolute w-full lg:w-1/2 px-1 lg:px-0 lg:mx-1"
              style={{ lineHeight: '1.25' }}
            >
              Hi, we're Inkpop, the Printing Company
            </p>
          </div>
        </div>
        <div className="py-2 lg:py-3p5">
          {this.renderOurMission()}
          {this.renderOurStory()}
          {this.renderOurProducts()}
        </div>
      </React.Fragment>
    );
  }
}
